<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.tourSect.initBtn') }}
      </div>
      <section class="modal--container choose--date-container">
        <date-picker
          v-model="dateValue"
          :minDate="new Date()"
          :inline="true"
          :to="to"
          :from="from"
          format="dd/MM/yyyy"
        ></date-picker>
        <hr class="separator" />
        <div class="btn btn-primary main--btn" @click="chooseDate">
          {{ $t('general.selectDate') }}
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const DatePicker = () => import('@/components/utils/date-picker');
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      modalName: 'modal--initiate-tour-1',
    };
  },
  mounted() {
    this.$store.commit('v2/rentTransaction/initiateTour/SET_DATE', new Date());
  },
  computed: {
    to: {
      get() {
        return this.$store.state.v2.rentTransaction.initiateTour.to;
      },
      set(value) {
        this.$store.commit('v2/rentTransaction/initiateTour/SET_TO', value);
      },
    },
    from: {
      get() {
        return this.$store.state.v2.rentTransaction.initiateTour.from;
      },
      set(value) {
        this.$store.commit('v2/rentTransaction/initiateTour/SET_FROM', value);
      },
    },
    dateValue: {
      get() {
        return this.$store.state.v2.rentTransaction.initiateTour.date;
      },
      set(value) {
        this.$store.commit('v2/rentTransaction/initiateTour/SET_DATE', value);
      },
    },
  },
  methods: {
    beforeOpen(event) {
      this.to = event.params.to;
      this.from = event.params.from;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    chooseDate() {
      this.$modal.hide(this.modalName);
      this.$modal.show('modal--initiate-tour-2');
    },
  },
};
</script>
